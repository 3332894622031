import { Image, Page, Text, View } from '@react-pdf/renderer';
import { useQuotePdfContext } from '@ui/pages/QuoteBuilder/hooks';
import type { FC } from 'react';
import { pdfStyles } from '../pdf-styles';
import { CustomerQuotePdfTemplate } from './template';

export const CustomerQuote: FC = () => {
  const { quoteId } = useQuotePdfContext();
  return (
    <Page size="A4" style={pdfStyles.page}>
      <View style={pdfStyles.row}>
        <View style={[pdfStyles.section, pdfStyles.alignCenter]}>
          <Image
            src={'/images/CAP/CAPITAL_SPRING_RGB_PRIMARY_LOGO_AW.png'}
            style={pdfStyles.logo}
          />
          <Text style={pdfStyles.textSmall}>AS9100 Registered</Text>
        </View>
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.bold}>Capital Springs</Text>
          <Text>2000 Jetway Blvd</Text>
          <Text>Columbus, OH 43219</Text>
          <Text>Phone (614) 418 - 0250</Text>
          <Text>Fax (614) 418 - 0260</Text>
          <Text>FEIN: 38-2938499</Text>
        </View>
        <View
          style={[
            pdfStyles.section,
            pdfStyles.alignCenter,
            pdfStyles.maxWidthFull,
          ]}
        >
          <Text style={[pdfStyles.quoteNumber, pdfStyles.bold]}>
            Quotation {quoteId}
          </Text>
          <Text style={pdfStyles.textSmall}>
            Please refer to the quotation number when ordering
          </Text>
        </View>
      </View>
      <CustomerQuotePdfTemplate showPartDescription={false} />
      <View style={pdfStyles.row}>
        <View style={pdfStyles.fullRow}>
          <Text>FOB:</Text>
        </View>
      </View>
      <View style={pdfStyles.row}>
        <View style={pdfStyles.fullRow}>
          <Text style={pdfStyles.textSmall}>
            CERTIFICATION, FAIR & PPAP CHARGES (If required):
          </Text>
          <Text style={pdfStyles.textSmall}>
            Certificate of Conformance: $35.00/ purchase order line item
          </Text>
          <Text style={pdfStyles.textSmall}>
            Material Cert: $30.00/ purchase order line item
          </Text>
          <Text style={pdfStyles.textSmall}>
            Inspection Report: $75.00/ purchase order line item
          </Text>
          <Text style={pdfStyles.textSmall}>FAIR (AS9102): $750.00</Text>
          <Text style={pdfStyles.textSmall}>
            PPAP: $1000.00 Other Certifications are quoted upon request.
          </Text>
        </View>
      </View>
      <View style={pdfStyles.row}>
        <View style={pdfStyles.fullRow}>
          <Text style={[pdfStyles.bold, pdfStyles.textSmall]}>
            Block Tolerances DO NOT APPLY to items on this quotation. Commercial
            tolerances per DIN 2089 will apply if not otherwise referenced on
            the drawing. We reserve the right to pass through increases in
            material, energy, freight and/or outside service cost in effect at
            the time of manufacture. Items ordered as part of a blanket or
            multiple release agreement must be taken with 12 months manufacture.
            Quoted delivery subject to material availability at time of order.
            DFAR items subject to material availability at time of order. To
            prevent any problems, parts not previously manufactured will have a
            quality assurance review prior to the start of production
          </Text>
        </View>
      </View>
    </Page>
  );
};
