import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  Image,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import {
  faChartPie,
  faChevronLeft,
  faChevronRight,
  faDashboard,
  faDatabase,
  faGears,
  faHandshake,
  faHelmetSafety,
  faQuoteLeft,
  faScrewdriverWrench,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { WithPermission } from '@ui/components/Permission';
import { type RouteItem, RoutesConfig } from '@ui/config/routes';
import { type FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../../../assets/logo.svg';
import { useLocalization } from '../../../config/localization/getLocalization';
import { useGlobalState } from '../../../hooks';
import Avatar from '../../Avatar';
import { NavItem } from './NavItem';
import { SiteSelector } from './SiteSelector';

export interface NavigationItem extends RouteItem {
  key: string;
  icon: FontAwesomeIconProps['icon'];
  /** Make sure subpaths start with their parent's path for accordion menus to work properly*/
  children?: Omit<NavigationItem, 'children'>[]; // only allow one-level deep children
}

const items: NavigationItem[] = [
  {
    ...RoutesConfig.quoteQueue,
    key: 'quote-queue',
    icon: faQuoteLeft,
  },
  // hidden per https://mwcomponents.atlassian.net/browse/MW-817
  // {
  //   ...RoutesConfig.dashboard,
  //   key: 'dashboard',
  //   icon: faDashboard,
  // },
  // {
  //   ...RoutesConfig.metrics,
  //   key: 'metrics',
  //   icon: faChartPie,
  // },
  // {
  //   ...RoutesConfig.customers,
  //   key: 'customers',
  //   icon: faHandshake,
  // },
  {
    ...RoutesConfig.admin,
    key: 'admin',
    icon: faScrewdriverWrench,
    children: [
      {
        ...RoutesConfig.adminConfig,
        key: 'admin.quoting',
        icon: faGears,
      },
      {
        ...RoutesConfig.adminUsers,
        key: 'admin.users',
        icon: faUsers,
      },
      {
        ...RoutesConfig.adminSites,
        key: 'admin.sites',
        icon: faHelmetSafety,
      },
      {
        ...RoutesConfig.adminMetrics,
        key: 'admin.metrics',
        icon: faDatabase,
      },
    ],
  },
];

export const NavigationBar: FC = () => {
  const i18n = useLocalization('NavigationBar');
  const { pathname } = useLocation();
  const { collapsedNavigation, setCollapsedNavigation } = useGlobalState();
  const windowWidthValueRef = useRef(window.innerWidth);
  const expandedMaxWidth = 200;

  useEffect(() => {
    const onResize = () => {
      windowWidthValueRef.current = window.innerWidth;

      if (window.innerWidth < 1000) {
        setCollapsedNavigation(true);
      } else {
        setCollapsedNavigation(false);
      }
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <Box
      as="nav"
      minWidth={collapsedNavigation ? '80px' : expandedMaxWidth}
      height="100vh"
      borderRight="1px solid"
      borderRightColor={'mw.black'}
      bgColor="mw.black"
      textColor="white"
      position={'sticky'}
      zIndex={1}
      top={0}
      left={0}
    >
      <Flex direction={'column'} height="100%">
        <VStack spacing="0px" alignItems="flex-start" marginTop={2}>
          {items.map((item) => (
            <WithPermission
              key={`nav-item-${item.key}`}
              permissions={item.permissions}
            >
              {item?.children && !collapsedNavigation ? (
                (() => {
                  const isCurPage = pathname.startsWith(item.path);
                  return (
                    <Accordion
                      key={isCurPage ? 'open' : 'close'}
                      index={isCurPage ? [0] : undefined}
                      allowToggle
                      reduceMotion
                      borderTopWidth={0}
                      borderBottomWidth={0}
                      borderStyle={'none'}
                      width={'100%'}
                      paddingRight={!collapsedNavigation ? 6 : 0}
                    >
                      <AccordionItem border="none">
                        <AccordionButton padding={0}>
                          <Box>
                            <NavItem
                              item={item}
                              curPath={pathname}
                              isCollapsed={collapsedNavigation}
                            />
                          </Box>
                          <Box
                            flexGrow={1}
                            marginLeft={3}
                            display={'flex'}
                            color={'mw.white'}
                            opacity={0.72}
                            _hover={
                              !isCurPage
                                ? {
                                    opacity: 1,
                                  }
                                : undefined
                            }
                            cursor={isCurPage ? 'default' : undefined}
                          >
                            <AccordionIcon marginLeft="auto" />
                          </Box>
                        </AccordionButton>
                        <AccordionPanel
                          padding={0}
                          paddingLeft={!collapsedNavigation ? 4 : 0}
                        >
                          {item.children.map((child) => (
                            <NavItem
                              key={child.key}
                              item={child}
                              curPath={pathname}
                              isCollapsed={collapsedNavigation}
                            />
                          ))}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  );
                })()
              ) : (
                <NavItem
                  item={item}
                  curPath={pathname}
                  isCollapsed={collapsedNavigation}
                />
              )}
            </WithPermission>
          ))}
        </VStack>

        <Button
          variant="ghost"
          color={'white'}
          width="20px"
          p={0}
          height="40px"
          minWidth={'unset'}
          onClick={() => setCollapsedNavigation(!collapsedNavigation)}
          backgroundColor="mw.darkBlue"
          _hover={{
            bg: 'mw.darkBlue',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
          }}
          overflow="hidden"
          position="absolute"
          top="50%"
          transform="translateY(-50%)"
          left={collapsedNavigation ? '68px' : `${expandedMaxWidth - 12}px`}
        >
          <FontAwesomeIcon
            fontSize={14}
            icon={!collapsedNavigation ? faChevronLeft : faChevronRight}
            title={
              collapsedNavigation ? i18n('expandMenu') : i18n('collapseMenu')
            }
          />
        </Button>

        <Spacer />

        <SiteSelector isCollapsed={collapsedNavigation} />

        <Center height="50px">
          <Avatar showDisplayName={false} />
        </Center>

        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          height={'50px'}
        >
          <Image
            src={logo}
            alt={i18n('logoAltText')}
            title={i18n('logoAltText')}
            maxWidth={'30px'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
